import React, { useState } from "react";
import { Table, TableContainer, Paper, Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import {  toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
import api from "../../api/tokenCode"; 
import "./NotificationManagement.css";
import { notifySuccess, notifyError } from "../../Constant/toast";


const NotificationManagement = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = async () => {
    if (!title || !body) {
      toast.error("Please fill out all fields."); 
      return;
    }

    const cleanBody = DOMPurify.sanitize(body, { ALLOWED_TAGS: [] });

    setIsLoading(true); 
    try {
    
      const response = await api.post("/admin/sendPushNotification", {
        title, 
        body: cleanBody, 
      });
      console.log(response,"test");
      

      if (response.status === 200) {
        notifySuccess("Notification sent successfully!"); 
        setTitle(""); 
        setBody(""); 
      } else {
        notifyError(`Failed to send notification: ${response.data.message || "Please try again."}`); 
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="notifiy-padding-container">
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F8FA", height: "86vh" }}
        >
          <Table>
            <div className="notify-header">
              <h2 className="header-name">Notification Management</h2>
            </div>
          </Table>
          <hr />
          <Table>
            <div className="email-category" >
              <input
                className="category-2"
                placeholder="Subject"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="message-input-container">
                <ReactQuill
                  className="react-quill"
                  value={body}
                  onChange={setBody}
                  placeholder="Type your message here..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ color: [] }, { background: [] }],
                      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                      ["link", "image", "video"],
                      ["clean"],
                    ],
                  }}
                />
                <button
                  onClick={handleSend}
                  className="send-button"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </Table>
        </TableContainer>
      </Box>

    </Box>
  );
};

export default NotificationManagement;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./StoreManagement.css";
import add from "../../assets/Walnut/add.svg";
import edit from "../../assets/Walnut/edit.svg";
import Delete from "../../assets/Walnut/delete.svg";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import api from "../../api/tokenCode";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../Constant/toast";

const StoreManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [totalCount, setTotalRows] = useState(0);
  const [productToEdit, setProductToEdit] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [currentView, setCurrentView] = useState("storeManagement");

  const fetchProducts = async () => {
    try {
      const response = await api.get("/admin/getProducts", {
        params: {
          search: searchTerm,
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      });
      const productList = response.data.list || [];
      setProducts(productList);
      setTotalRows(response.data.count);
      // Handle case when the current page is empty after deletion
      if (productList.length === 0 && page > 0) {
        setPage((prevPage) => prevPage - 1); // Redirect to the previous page
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const fetchPurchaseHistory = async () => {
    try {
      const response = await api.get("/admin/getPurchaseHistory", {
        params: {
          search: searchTerm,
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      });
      const purchaseHistoryList = response.data.list || [];
      setPurchaseHistory(purchaseHistoryList);
      setTotalRows(response.data.count);
    } catch (error) {
      setPurchaseHistory([]);
    }
  };

  useEffect(() => {
    if (currentView === "storeManagement") {
      fetchProducts();
    } else if (currentView === "purchaseHistory") {
      fetchPurchaseHistory();
    }
  }, [currentView, page, rowsPerPage, searchTerm]);

  useEffect(() => {
    if (needsRefresh) {
      if (currentView === "storeManagement") {
        fetchProducts();
      } else if (currentView === "purchaseHistory") {
        fetchPurchaseHistory();
      }
      setNeedsRefresh(false);
    }
  }, [needsRefresh]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setNeedsRefresh(true);
  };

  const handleOpenEditModal = (product) => {
    setProductToEdit(product);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setProductToEdit(null);
    setNeedsRefresh(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteProduct = async () => {
    try {
      const response = await api.delete(`/admin/deleteProducts/${productToDelete}`);
      if (response.status === 200) {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product._id !== productToDelete)
        );
        setNeedsRefresh(true);
        notifySuccess("Your product has been deleted successfully.", {
          position: "top-right",
        });
      }
    } catch (error) {
      notifyError("Failed to delete product.", {
        position: "top-right",
      });
    } finally {
      setDeleteDialogOpen(false);
      setProductToDelete(null);
    }
  };

  const openDeleteDialog = (id) => {
    setProductToDelete(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProductToDelete(null);
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
  };

  const handleToggleView = (view) => {
    setCurrentView(view);
  };


  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="store-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="store-header">

              <div className="purchase-header">
                <h2
                  onClick={() => handleToggleView("storeManagement")}
                  className={currentView === "storeManagement" ? "tab active-tab" : "tab"}

                >
                  Store Management
                </h2>
                <h2
                  onClick={() => handleToggleView("purchaseHistory")}
                  className={currentView === "purchaseHistory" ? "tab active-tab" : "tab"}
                >
                  Purchase History
                </h2>
              </div>


              {currentView === "storeManagement" && (
                <div className="store-addnew" >
                  <Button
                    className="add-btn"
                    sx={{
                      color: "white",
                      backgroundColor: "Black",
                      gap: "10px",
                      border: "1px solid #ccc",
                      height: "40px",
                      width: "125px",
                      padding: "8px",
                    }}
                    onClick={handleOpenAddModal}
                  >
                    Add New
                    <img src={add} alt="add" />
                  </Button>
                </div>
              )}
            </div>
          </Table>
          <hr />

          {/* Conditionally render based on selected view */}
          {currentView === "storeManagement" ? (
            <>
              {/* Store Management Content */}
              <Table className="store-table">
                <div className="store-tablediv">
                  <div className="store-filters">
                    <input
                      name="search"
                      className="store-search-text"
                      placeholder="Search by Product name"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </Table>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: "separate",
                  borderSpacing: "0 20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHeaderCellStyle}>
                      Product Image
                    </TableCell>
                    <TableCell sx={tableHeaderCellStyle}>
                      Product Name
                    </TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Price</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Location</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length > 0 ? (
                    products.map((product) => (
                      <TableRow key={product._id}>
                        <TableCell sx={tableCellStyle}>
                          <img
                            src={product.productPicter}
                            alt={product.productName}
                            className="product-image"
                          />
                        </TableCell>
                        <TableCell
                          sx={tableCellStyle}
                          className="capitalize-product"
                        >
                          {product.productName}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>{product.price}</TableCell>
                        <TableCell sx={tableCellStyle}>{product.locationName || 'N/A'}</TableCell>
                        <TableCell sx={tableCellStyle}>
                          <img
                            src={edit}
                            alt="Edit"
                            onClick={() => handleOpenEditModal(product)}
                            className="product-edit"
                          />
                          <img
                            src={Delete}
                            alt="Delete"
                            onClick={() => openDeleteDialog(product._id)}
                            className="product-delete"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        sx={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={products.length > 0 ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              {/* Purchase History Content */}
              <Table className="store-table">
                <div className="store-tablediv">
                  <div className="store-filters">
                    <input
                      name="search"
                      className="store-search-text"
                      placeholder="Search member by name"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </Table>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: "separate",
                  borderSpacing: "0 20px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHeaderCellStyle}>First Name</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Last Name</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Purchase Item</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Quantity</TableCell>
                    <TableCell sx={tableHeaderCellStyle}>Date</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseHistory.length > 0 ? (
                    purchaseHistory.map((history) => (
                      history.iteams.map((items) => (
                        <TableRow key={items._id}>
                          <TableCell sx={tableCellStyle} className="capitalize-product">
                            {history.firstName}
                          </TableCell>
                          <TableCell sx={tableCellStyle} className="capitalize-product">
                            {history.lastName}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>{items.name}</TableCell>
                          <TableCell sx={tableCellStyle}>{items.quantity}</TableCell>
                          <TableCell sx={tableCellStyle}>
                            {new Date(history.createdAt).toLocaleDateString("en-US").replace(/\//g, "-")}
                          </TableCell>
                        </TableRow>
                      ))
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={purchaseHistory.length > 0 ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </>

          )}
        </TableContainer>

        <AddProductModal open={openAddModal} handleClose={handleCloseAddModal} />
        <EditProductModal
          open={openEditModal}
          handleClose={handleCloseEditModal}
          product={productToEdit}
        />
        {/* Delete Dialog */}
        <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
          <DialogTitle sx={{ fontSize: 'xx-large', fontWeight: 700, textAlign: 'center' }}>
            Delete Product
          </DialogTitle>

          <DialogContent>
            Are you sure you want to delete this product?
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px", gap: "20px" }}>
            <Button onClick={closeDeleteDialog} color="primary" sx={{
              backgroundColor: "white",
              color: "black",
              padding: "6px 8px",
              fontSize: "12px",
              border: "1px solid black",
              textAlign: "center",
            }} >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteProduct}
              color="secondary"
              variant="contained"
              sx={{ backgroundColor: "black", color: "white", padding: "6px 8px", fontSize: "12px" }}
            >
              Delete
            </Button>
          </DialogActions>

        </Dialog>
      </Box>
    </Box>
  );
};

export default StoreManagement;




import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/tokenCode";

// Async thunk to fetch users from API
export const fetchSubscribedUsers = createAsyncThunk(
  "subscribedUsers/fetchSubscribedUsers",
  async ({ filter, page, rowsPerPage }, { rejectWithValue }) => {
    try {
      const skip = page * rowsPerPage;
      const response = await api.get("/admin/getSubscribedUsers", {
        params: { search: filter.search, limit: rowsPerPage, skip },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching users");
    }
  }
);

const subscribedUsersSlice = createSlice({
  name: "subscribedUsers",
  initialState: {
    members: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  reducers: {
    setSubscribedUser: (state, action) => {
      state.members = action.payload.list;
    },
    removeSubscribedUser: (state, action) => {
      state.members = state.members.filter(
        (member) => member.id !== action.payload
      );
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscribedUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscribedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload.list;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchSubscribedUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSubscribedUser, removeSubscribedUser } = subscribedUsersSlice.actions;
export default subscribedUsersSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import subscribedUsersReducer from "../Slice/subscribedUsersSlice";

const store = configureStore({
  reducer: {
    subscribedUsers: subscribedUsersReducer,
  },
});

export default store;

import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    CircularProgress,
    InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./MemberManagement.css";
import api from "../../api/tokenCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../Constant/toast";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscribedUsers, setSubscribedUser } from "../Slice/subscribedUsersSlice";


const EditMemberModal = ({ open, handleClose, member, filter, page, rowsPerPage }) => {

    const dispatch = useDispatch();
    const { members, totalCount, loading, error } = useSelector((state) => state.subscribedUsers);

    const fetchUsers = async () => {

        const resultAction = await dispatch(fetchSubscribedUsers({ filter, page, rowsPerPage }));
        if (fetchSubscribedUsers.fulfilled.match(resultAction)) {
            const responseData = resultAction.payload;
            dispatch(setSubscribedUser(responseData));
        }
    }


    useEffect(() => {
        fetchUsers()
    }, [dispatch, filter, page, rowsPerPage]);

    const [memberData, setMemberData] = useState({
    });

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error}</p>;



    useEffect(() => {
        if (member) {
            setMemberData({
                firstName: member.firstName || "",
                lastName: member.lastName || "",
                email: member.email || "",
                createdAt: member.createdAt || "",
                SubscriptionPlan: member.SubscriptionPlan || "",
                status: member.status || "",
                role: member.role || "",
                userId: member._id
            });
        }
    }, [member]);

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const adminUserId = localStorage.getItem("userId");
    const [role, setRole] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("");
    const [generalError, setGeneralError] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setMemberData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        if (name === "SubscriptionPlan") {
            setSelectedPlan(value); // Track selected plan
        }

        setRole(value);
    };


    const validateForm = (apiError = null) => {
        const newErrors = {};
        let isValid = true;

        if (!memberData.firstName) {
            newErrors.firstName = "First Name is required";
            isValid = false;
        }
        if (!memberData.lastName) {
            newErrors.lastName = "Last Name is required";
            isValid = false;
        }
        if (!memberData.createdAt) {
            newErrors.createdAt = "Join date is required";
            isValid = false;
        }
        if (!memberData.email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(memberData.email)) {
            newErrors.email = "Email is invalid";
            isValid = false;
        }
        if (!memberData.role) {
            newErrors.role = "Role is required";
            isValid = false;
        }
        if (memberData.role === "user" && !memberData.SubscriptionPlan) {
            newErrors.planType = "Plan is required for Standard User";
        }

        // Handle API validation errors dynamically
        if (apiError?.field && apiError?.message) {
            newErrors[apiError.field] = apiError.message;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            try {
                const response = await api.put("/admin/UpdateUser", {
                    firstName: memberData.firstName,
                    lastName: memberData.lastName,
                    email: memberData.email,
                    createdAt: memberData.createdAt,
                    password: memberData.password,
                    role:
                        memberData.role === "Admin"
                            ? "A"
                            : memberData.role === "User"
                                ? "U"
                                : "FU",
                    subscriptionId: memberData.subscriptionId || "",
                    userId: memberData.userId,
                    planType: memberData.planType,
                });

                if (response.status === 200) {
                    resetForm();
                    handleClose();
                    setSelectedPlan("")
                    dispatch(fetchSubscribedUsers({ filter, page, rowsPerPage }));
                    setTimeout(() => {
                        notifySuccess("User Updated Successfully!");
                    }, 500);
                } else {
                    throw new Error("Failed to Update user");
                }
            }
            catch (error) {
                console.error(error.response?.data?.message || error.message);

                if (error.response && error.response.data) {
                    let message = error.response.data.message || "Something went wrong";

                    // Customize the message for subscription errors
                    if (message.includes("No such subscription")) {
                        message = "Invalid Subscription Id";
                    } else if (message.includes("duplicate key error collection")) {
                        message = "Email already exists";
                    }

                    let field = "";
                    if (message.includes("Subscription")) field = "subscriptionId";
                    else if (message.includes("Email")) field = "email";
                    else field = "general";

                    if (field === "general") {
                        setGeneralError(message);
                    } else {
                        validateForm({ field, message });
                    }
                }
            }
            finally {
                setIsSubmitting(false);
            }
        }
    };

    const resetForm = () => {
        setMemberData({
            firstName: "",
            lastName: "",
            email: "",
            createdAt: "",
            password: "",
            role: "",
            subscriptionId: "",
            planType: ""
        });
        setErrors({});
    };

    const handleCloseWithReset = () => {
        resetForm();
        handleClose();
        setSelectedPlan("")
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} />{" "}

            <Dialog
                open={open}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") return;
                    handleCloseWithReset();
                }}
                fullWidth
                maxWidth="md"
                sx={{
                    "& .MuiDialog-paper": {
                        overflowX: "hidden",
                    },
                }}
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >

                <DialogTitle
                    sx={{ backgroundColor: "black", color: "white", padding: "16px" }}
                >
                    Edit a Member
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseWithReset}
                        aria-label="close"
                        sx={{ position: "absolute", right: 20, top: 8, color: "white" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <form onSubmit={handleSubmit} className="createForm">
                        <div className="input-container" >
                            <div className="member-div" >
                                <label className="member-lable">
                                    First Name<span className="member-star">*</span>
                                </label>
                                <TextField
                                    placeholder="First Name"
                                    variant="outlined"
                                    fullWidth
                                    name="firstName"
                                    value={memberData.firstName}
                                    onChange={handleInputChange}
                                    margin="dense"
                                    required
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                    sx={{
                                        "& input": {
                                            textTransform: "capitalize",
                                        },
                                    }}
                                />
                            </div>
                            <div className="member-div">
                                <label className="member-lable">
                                    Last Name<span className="member-star">*</span>
                                </label>
                                <TextField
                                    placeholder="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    name="lastName"
                                    value={memberData.lastName}
                                    onChange={handleInputChange}
                                    margin="dense"
                                    required
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                    sx={{
                                        "& input": {
                                            textTransform: "capitalize",
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container-2">
                            <div>
                                <label className="member-lable">
                                    Email Address<span className="member-star">*</span>
                                </label>
                                <TextField
                                    placeholder="Email Address"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={memberData.email}
                                    onChange={handleInputChange}
                                    margin="dense"
                                    required
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </div>
                            <div className="member-div">
                                <label className="member-lable">
                                    Join Date<span className="member-star">*</span>
                                </label>
                                <TextField
                                    placeholder="Join Date"
                                    variant="outlined"
                                    fullWidth
                                    name="createdAt"
                                    disabled
                                    value={
                                        memberData?.createdAt
                                            ? new Date(memberData.createdAt).toLocaleDateString("en-US").replace(/\//g, "-")
                                            : ""
                                    }
                                    onChange={handleInputChange}
                                    margin="dense"
                                    required
                                    error={!!errors.createdAt}
                                    helperText={errors.createdAt}
                                    sx={{
                                        "& input": {
                                            textTransform: "capitalize",
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="input-container-2">
                            <div className="member-div">
                                <label className="member-lable">
                                    User Type<span className="member-star">*</span>
                                </label>
                                <FormControl fullWidth required className="formDesign">
                                    {memberData.role !== undefined && (
                                        <Select
                                            name="role"
                                            value={memberData.role}
                                            onChange={handleInputChange}
                                            displayEmpty
                                            error={!!errors.role}
                                        >
                                            <MenuItem value="" disabled>
                                                Select Role
                                            </MenuItem>
                                            <MenuItem value="Admin">Admin</MenuItem>
                                            <MenuItem value="User">Standard User</MenuItem>
                                            <MenuItem value="Free user">Free Unlimited User</MenuItem>
                                            {/* <MenuItem value="punch users">Punch Pass</MenuItem> */}
                                        </Select>
                                    )}
                                    <div className={`member-menurole error-message ${errors.role ? "visible" : ""}`}>
                                        {errors.role}
                                    </div>
                                </FormControl>
                            </div>

                            <div className="member-div">
                                <label className="member-lable">Status<span className="member-star">*</span></label>
                                <TextField
                                    placeholder="Status"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    name="status"
                                    value={memberData.status}
                                    onChange={handleInputChange}
                                    margin="dense"
                                    error={!!errors.status}
                                    helperText={errors.status}
                                />
                            </div>
                        </div>

                        <div className="input-container-2">
                            {/* Select Plan Field */}
                            <div className="member-div">
                                <label className="member-lable">
                                    Subscription Plan<span className="member-star">*</span>
                                </label>
                                <FormControl fullWidth required className="formDesign">
                                    <Select
                                        name="SubscriptionPlan"
                                        value={memberData.SubscriptionPlan || ""}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        error={!!errors.SubscriptionPlan}
                                    >
                                        <MenuItem value="" disabled>Select Plan</MenuItem>
                                        <MenuItem value="Unlimited - 6 Month Pass">Unlimited - 6 Month</MenuItem>
                                        <MenuItem value="Unlimited - Annual Plan">Unlimited - Annual</MenuItem>
                                        <MenuItem value="Unlimited Month to Month">Unlimited - Month to Month</MenuItem>
                                        <MenuItem value="4 Hours/Month">4 Hours Per Month</MenuItem>
                                        <MenuItem value="Shared Membership Plan">Shared - Student Plan</MenuItem>
                                        <MenuItem value="Corporate Plan">Corporate - Annual</MenuItem>
                                    </Select>

                                    <div className={`member-menurole error-message ${errors.planType ? "visible" : ""}`}>
                                        {errors.planType}
                                    </div>
                                </FormControl>
                            </div>

                            {/* Conditionally Render Subscription ID Field */}
                            {selectedPlan && (
                                <div className="member-div">
                                    <label className="member-lable">Subscription ID</label>
                                    <TextField
                                        placeholder="Subscription ID"
                                        variant="outlined"
                                        fullWidth
                                        name="subscriptionId"
                                        value={memberData.subscriptionId}
                                        onChange={handleInputChange}
                                        margin="dense"
                                        error={!!errors.subscriptionId}
                                        helperText={errors.subscriptionId}
                                        sx={{ marginTop: "8px", marginBottom: "0px", paddingBottom: "27px" }}
                                    />
                                </div>
                            )}
                        </div>


                    </form>
                </DialogContent>
                <DialogActions className="dialogButton">
                    <button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCloseWithReset}
                        className="member-cancel"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className="member-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <CircularProgress size={24} color="secondary" />
                        ) : (
                            "Update"
                        )}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditMemberModal;
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./CreateUser.css";
import api from "../../api/tokenCode";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../Constant/toast";


const CreateUser = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    subscriptionId: "",
    planType: ""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const adminUserId = localStorage.getItem("userId");
  const [paddingActive, setPaddingActive] = useState(false);
  const [role, setRole] = useState("");
  const [generalError, setGeneralError] = useState("");


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setRole(e.target.value);

  };

  const validateForm = (apiError = null) => {
    const newErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    if (apiError?.field === "email") {
      newErrors.email = apiError.message;
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must contain at least 6 characters";
      isValid = false;
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
      isValid = false;
    }
    if (formData.role === "user" && !formData.planType) {
      newErrors.planType = "Plan is required for Standard User";
    }

    if (apiError?.field && apiError?.message) {
      newErrors[apiError.field] = apiError.message;
      isValid = false;
  }

    setErrors(newErrors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaddingActive(true);

    if (validateForm()) {
      setIsSubmitting(true);

      try {
        const response = await api.post("/admin/createUser", {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          role:
            formData.role === "admin"
              ? "A"
              : formData.role === "user"
                ? "U"
                : "FU",
          subscriptionId: formData.subscriptionId || "",
          userId: adminUserId,
          planType: formData.planType,

        });

        if (response.status === 200) {
          resetForm();
          handleClose();
          setTimeout(() => {
            notifySuccess("User created successfully!");
          }, 500);
        } else {
          throw new Error("Failed to create user");
        }
      } catch (error) {

        if (error.response && error.response.data) {
          const message = error.response.data.message || "Something went wrong";

          let field = "";
          if (message.includes("subscription")) field = "subscriptionId";
          else if (message.includes("Email")) field = "email";
          else field = "general";

          if (field === "general") {
            setGeneralError(message);
          } else {
            validateForm({ field, message });
          }
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      subscriptionId: "",
      planType: ""
    });
    setErrors({});
  };

  const handleCloseWithReset = () => {
    resetForm();
    handleClose();
  };

  return (
    <>

      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason === "backdropClick") return;
          handleCloseWithReset();
        }}
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            overflowX: "hidden",
          },
        }}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >

        <DialogTitle
          sx={{ backgroundColor: "black", color: "white", padding: "16px" }}
        >
          Create an User
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseWithReset}
            aria-label="close"
            sx={{ position: "absolute", right: 20, top: 8, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <span className="inline-container">
            <p>Please fill in the details to create new user</p>
          </span>
          <form onSubmit={handleSubmit} className="createForm">
            <div className="input-container" >
              <div className="create-div" >
                <label className="create-lable">
                  First Name<span className="create-star">*</span>
                </label>
                <TextField
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  sx={{
                    "& input": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </div>
              <div className="create-div">
                <label className="create-lable">
                  Last Name<span className="create-star">*</span>
                </label>
                <TextField
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  sx={{
                    "& input": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </div>
            </div>
            <div className="input-container-2">
              <div>
                <label className="create-lable1">
                  Email Address<span className="create-star">*</span>
                </label>
                <TextField
                  placeholder="Email Address"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </div>
              <div>
                <label className="create-lable1" >
                  Set Password<span className="create-star">*</span>
                </label>
                <TextField
                  placeholder="Set Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="input-container-1">
              <div className="create-div">
                <label className="create-lable1">
                  Role<span className="create-star">*</span>
                </label>
                <FormControl
                  fullWidth
                  required
                  sx={{
                    marginTop: "8px",
                    marginBottom: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    displayEmpty
                    error={!!errors.role}
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">Standard User</MenuItem>
                    <MenuItem value="guest">Free Unlimited User</MenuItem>
                  </Select>
                  <div
                    className="create-menurole"
                    style={{
                      minHeight: "23px",
                      visibility: errors.role ? "visible" : "hidden",
                    }}
                  >
                    {errors.role}
                  </div>
                </FormControl>
              </div>

              {/* Select Plan Field - Shown only if role is "user" */}
              {formData.role === "user" && (
                <div className="create-div">
                  <label className="create-lable1">
                    Select Plan<span className="create-star">*</span>
                  </label>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      marginTop: "8px",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <Select
                      name="planType"
                      value={formData.planType}
                      onChange={handleInputChange}
                      displayEmpty
                      error={!!errors.planType}
                    >
                      <MenuItem value="" disabled>
                        Select Plan
                      </MenuItem>
                      <MenuItem value="Unlimited - 6 Month Pass">Unlimited - 6 Month</MenuItem>
                      <MenuItem value="Unlimited - Annual Plan">Unlimited - Annual</MenuItem>
                      <MenuItem value="Unlimited Month to Month">Unlimited - Month to Month</MenuItem>
                      <MenuItem value="4 Hours/Month">4 Hours Per Month</MenuItem>
                      <MenuItem value="Shared Membership Plan">Shared - Student Plan</MenuItem>
                      <MenuItem value="Corporate Plan">Corporate - Annual</MenuItem>
                    </Select>

                    <div className={`create-menurole error-message ${errors.planType ? "visible" : ""}`}>
                      {errors.planType}
                    </div>
                  </FormControl>
                </div>
              )}

              {/* Subscription ID Field - Shown only if role is "user" */}
              {formData.role === "user" && (
                <div className="create-div">
                  <label className="create-lable1">Subscription ID</label>
                  <TextField
                    placeholder="Subscription ID"
                    variant="outlined"
                    fullWidth
                    name="subscriptionId"
                    value={formData.subscriptionId}
                    onChange={handleInputChange}
                    margin="dense"
                    error={!!errors.subscriptionId}
                    helperText={errors.subscriptionId}
                    sx={{
                      marginTop: "8px",
                      marginBottom: "0px",
                      paddingBottom: "27px"

                    }}
                  />
                </div>
              )}
            </div>


          </form>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F6F8FA",
            padding: "16px",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <button
            variant="outlined"
            color="secondary"
            onClick={handleCloseWithReset}
            className="create-cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="create-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              "Create User"
            )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUser;
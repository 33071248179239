import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import api from "../../api/tokenCode";
import "./MemberManagement.css";
import JoinDate from "../../assets/Walnut/joindate.svg";
import plan from "../../assets/Walnut/plan.svg";
import status from "../../assets/Walnut/status.svg";
import Frame from "../../assets/Walnut/Frame.svg";
import email from "../../assets/Walnut/email.svg";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import CloseIcon from "@mui/icons-material/Close";
import { notifySuccess, notifyError } from "../../Constant/toast";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../assets/Walnut/edit.svg";
import Delete from "../../assets/Walnut/delete.svg";
import EditMemberModal from "./EditMember";
import { useSelector, useDispatch } from "react-redux";
import { removeSubscribedUser } from "../Slice/subscribedUsersSlice";
import { fetchSubscribedUsers } from "../Slice/subscribedUsersSlice";

const getInitials = (name) => {
  const names = name.split(" ");
  return names.length > 1
    ? names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
    : names[0].charAt(0).toUpperCase();
};

const MemberManagement = () => {
  // const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    plan: "",
    joinDate: "",
  });
  const { members, totalCount } = useSelector((state) => state.subscribedUsers);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [totalCount, setTotalCount] = useState(0);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [note, setNote] = useState("");
  const [currentMemberForNote, setCurrentMemberForNote] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();


  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setMemberToEdit(null);
    setNeedsRefresh(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setMemberToDelete(null);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });

    setPage(0);
  };


  const handleEditMember = (member, e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }

    setSelectedMember(member);
  };

  const handleCloseDetails = (e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    setSelectedMember(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
    setNote("");
  };


  const handleOpenNoteModal = (member) => {
    setCurrentMemberForNote(member);
    setNote(member.note || "");
    setOpenNoteModal(true);
  };

  const openDeleteDialog = (id) => {
    setMemberToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleOpenEditModal = (member) => {
    setMemberToEdit(member);
    setOpenEditModal(true);
  };

  const handleDeleteMember = async () => {
    try {
      const response = await api.put(`/admin/deleteUser?userId=${memberToDelete}`);
      if (response.status === 200) {
        // Update Redux store immediately to reflect changes in the UI
        dispatch(removeSubscribedUser(memberToDelete));

        notifySuccess("Member has been deleted successfully.", {
          position: "top-right",
        });
        dispatch(fetchSubscribedUsers({ filter, page, rowsPerPage }));
      }
    } catch (error) {
      notifyError("Failed to delete member.", {
        position: "top-right",
      });
    } finally {
      setTimeout(() => {
        setDeleteDialogOpen(false);
        setMemberToDelete(null);
      }, 1000);
    }
  };

  const handleSaveNote = async () => {
    try {
      const payload = {
        userId: currentMemberForNote._id,
        note: note,
      };

      const response = await api.put("/admin/addNote", payload);

      const updatedNote = response.data.UserDetails.note;

      if (response.status === 200) {
        // setMembers(updatedMembers);

        if (!currentMemberForNote.note || currentMemberForNote.note.trim() === "") {
          notifySuccess("Notes added successfully!");
        } else {
          notifySuccess("Notes updated successfully!");
        }

        setNote(updatedNote);

        setOpenNoteModal(false);
        setNote("");
      } else {
        console.error("Failed to save note:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving note:", error.message);
    }
  };

  const ProfileImage = ({ name }) => {
    const initials = getInitials(name);

    return <div className="profile-image">{initials}</div>;
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
    cursor: "pointer",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />

      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2 className="header-name">Member Management</h2>
            </div>
          </Table>
          <hr />
          <Table>
            <div className="member-table" >
              <div
                className="filters"
              >
                <input
                  name="search"
                  className="search-textj"
                  placeholder="Search by name & email "
                  value={filter.search}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>First Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Last Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>
                  Email Address
                </TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Join Date</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Subscription Plan </TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Status</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>User Type</TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Note</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {members.length > 0 ? (
                members.map((member) => (
                  <React.Fragment key={member._id}>
                    <TableRow onClick={(e) => handleEditMember(member, e)}>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.firstName}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.lastName}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.email}</TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>
                        {new Date(member.createdAt).toLocaleDateString("en-US").replace(/\//g, "-")}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.SubscriptionPlan.replace(/([a-zA-Z])([0-9])/g, "$1 $2")
                          .replace(/([0-9])([a-zA-Z])/g, "$1 $2")
                          .replace(/([a-zA-Z])([A-Z])/g, "$1 $2")
                          .replace(/_/g, "-")
                          .replace(/-/g, " - ")}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.status}</TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.role}</TableCell>

                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} onClick={(e) => {
                        e.stopPropagation();
                        handleOpenNoteModal(member);
                      }}>
                        <img
                          src={Frame}
                          alt="Frame"
                          className="frame-image"
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyle} onClick={(e) => e.stopPropagation()} >
                        <img
                          src={edit}
                          alt="Edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditModal(member)
                          }}
                          className="product-edit"
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            openDeleteDialog(member._id)
                          }}
                          className="product-delete"
                        />
                      </TableCell>

                    </TableRow>

                    {selectedMember && selectedMember._id === member._id && (
                      <TableRow>
                        <TableCell colSpan={8} sx={{ backgroundColor: "#EEF0F1", padding: "20px" }}>
                          <div className="member-details">

                            <div className="member-iconsection">
                              <CloseIcon
                                onClick={(e) => handleCloseDetails(e)}
                                className="member-closeicon"
                              />
                            </div>

                            <div className="selectmemSection">
                              <div className="profile" >
                                <ProfileImage name={`${selectedMember.firstName}`} />
                                <div>
                                  <h3 className="capitalize">{selectedMember.firstName}</h3>
                                  <div className="memberemail-image ">
                                    <img
                                      src={email}
                                      alt="email"
                                      className="email-image"

                                    />
                                    <p className="member-p-tag">{selectedMember.email}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="member-meta">
                                <div
                                  className="mebermeta-divs"

                                >
                                  <img src={JoinDate} alt="joindate" />
                                  <ul className="dash-ul">
                                    <li>Join Date:</li>
                                    <li>
                                      {new Date(selectedMember.createdAt)
                                        .toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })
                                        .replace(/\//g, "-")}
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="mebermeta-divs"


                                >
                                  <img src={plan} alt="plan" />
                                  <ul className="dash-ul">
                                    <li>Current Plan:</li>
                                    <li>{selectedMember.SubscriptionPlan}</li>
                                  </ul>
                                </div>
                                <div
                                  className="mebermeta-divs"

                                >
                                  <img src={status} alt="status" />
                                  <ul className="dash-ul">
                                    <li>Status:</li>
                                    <li>{selectedMember.status}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ textAlign: "center", padding: "20px", fontSize: "20px", fontWeight: "bold" }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={members.length > 0 ? totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>



      {/* Edit Member Modal */}
      <EditMemberModal
        open={openEditModal}
        handleClose={handleCloseEditModal}
        member={memberToEdit} // Pass the selected member
        filter={filter}
        rowsPerPage={rowsPerPage}
        page={page}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            closeDeleteDialog();
          }
        }}
        fullWidth
      >
        <DialogTitle className="titleDialog">
          Delete Member
        </DialogTitle>

        <DialogContent sx={{ textAlign: "center", fontSize: "18px" }}>
          Are you sure you want to delete this member?
        </DialogContent>
        <DialogActions className="actionDialog">
          <Button onClick={closeDeleteDialog} color="primary" className="cancelBtn" >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteMember}
            color="secondary"
            variant="contained"
            className="deleteBtn"
          >
            Delete
          </Button>
        </DialogActions>

      </Dialog>

      {/* Add Note Modal */}

      <Dialog
        open={openNoteModal}
        onClose={handleCloseNoteModal}
        sx={{ "& .MuiDialog-paper": { width: "600px", maxWidth: "90%" } }}
      >
        <DialogTitle className="titleCss">Notes</DialogTitle>  <DialogContent>
          <div className="note">
            <label className="note-label">Add your notes:</label>
            <textarea
              placeholder="Type Your Notes...."
              className="note-textarea"
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

        </DialogContent>
        <DialogActions className="actionDialog">

          <Button onClick={handleCloseNoteModal}
            sx={{
              backgroundColor: "white",
              color: "black",
              padding: "6px 8px",
              fontSize: "12px",
              border: "1px solid black",
              width: "120px",
              textAlign: "center",
            }}  >
            Cancel
          </Button>
          <Button onClick={handleSaveNote}
            sx={{
              backgroundColor: "black",
              color: "white",
              padding: "6px 8px",
              fontSize: "12px",
              width: "120px",
              textAlign: "center",

            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MemberManagement;



